import useQueryState from '@/infrastructure/hooks/useQueryState';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TabProps as MuiTabProps, TabsProps as MuiTabsProps, Box, Tab } from '@mui/material';
import { ReactNode } from 'react';

export type LtTabsProps = MuiTabsProps & {
  tabs: Array<
    MuiTabProps & {
      component: ReactNode;
    }
  >;
  onChangeCallback?: (val: any) => void;
};

const LtTabs = (props: LtTabsProps) => {
  const { tabs, onChangeCallback, ...tabsProps } = props;

  const [value, setValue] = useQueryState('tab', tabs[0].value, qval =>
    tabs.some(({ value }) => value === qval),
  );

  const isVertical = tabsProps.orientation === 'vertical';

  return (
    <Box sx={isVertical && { display: 'flex' }}>
      <TabContext value={value}>
        <TabList
          value={value}
          onChange={(_, val) => {
            setValue(val);
            onChangeCallback(val);
          }}
          sx={
            isVertical && {
              borderRight: '1px solid',
              borderColor: 'divider',
              overflow: 'visible',
              minWidth: 'fit-content',
              ...tabsProps?.sx,
            }
          }
          {...tabsProps}
        >
          {tabs
            .filter(({ hidden }) => !hidden)
            .map(({ component, ...tabProps }, index) => (
              <Tab
                key={index}
                sx={
                  isVertical && {
                    whiteSpace: 'nowrap',
                    paddingRight: '3rem',
                    minHeight: '5.6rem',
                    justifyContent: 'flex-start',
                    ...tabProps?.sx,
                  }
                }
                {...tabProps}
              />
            ))}
        </TabList>
        <TabPanel value={value} sx={{ p: 0, flex: 1 }}>
          {tabs.find(c => c.value === value)?.component}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LtTabs;
