import { useState, useEffect, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { getThemeLinkTypes } from '../../../../application/actions/md/profiles';
import Search from './search';
import Table from './table';
import AddEmployee from './add-employee';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { TableUiHandle } from './table/components/table';
import { ApiFile, Link, Account } from '../../../../shared/types/api';
import useCsvbox, { licenseAlertType } from '../../../../infrastructure/hooks/useCsvbox';
import nl2br from 'react-nl2br';
import useQuery from '../../../../infrastructure/hooks/useQuery';
import withNav from '../../../../infrastructure/hoc/withNav';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { Box, Button as MUIButton, Paper, Typography } from '@mui/material';
import { AddOutlined, KeyboardArrowDown } from '@mui/icons-material';
import LtMultiActionButton from '@/components/LtMultiActionButton';
import { AddUserToUnitPopup } from '../units/components/AddUserToUnitPopup';
import { LtDialog } from '@/components';
import { CSS_VARS } from '@/infrastructure/constants';
import { CustomTableToolbar } from '@/components/Table';
import { SortOption } from '@/shared/types/global';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { createEmployees } from '@/infrastructure/apis/md/profiles';
import { HelpButton } from '@/components/HelpSidebar';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import FeatureTeaser from '../teaser/featureTeaser';
import profilesTeaserImg from '@/views/images/teaser/profiles.svg';

const MdProfiles = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();
  const [addEmployeeDrawerOpened, setAddEmployeeDrawerOpened] = useState(false);
  const { employees } = useAppSelector(state => state.md);
  const account = useAppSelector<Account>(state => state.account);
  const theme = account.theme;
  const [parentSearchCondition, setParentSearchCondition] = useState('');
  const [parentSelectedColumn, setParentSelectedColumn] = useState<string>();
  const [selectedLink, setSelectedLink] = useState<Link>();
  const [selectedFile, setSelectedFile] = useState<ApiFile>();
  const [showModalLicense, setShowModalLicense] = useState(false);
  const [LicenseModalContents, setLicenseModalContent] = useState<licenseAlertType>(
    'noOrderCardWithExceedLicense',
  );
  const [orderCard, setOrderCard] = useState(0);
  const [isOneUserUpload, setIsOneUserUpload] = useState(false);
  const [importedData, setImportedData] = useState();
  const [createEmployeeForm, setCreateEmployeeForm] = useState();
  useEffect(() => {
    dispatch(getThemeLinkTypes(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('firstName');
  const [orderBy, setOrderBy] = useState(null);
  const [sort, setSort] = useState(null);

  const [addUserToUnitExplainerOpened, setAddUserToUnitExplainerOpened] = useState(false);

  const searchRef = useRef<{ refreshEmployees: () => void }>();
  const refreshEmployees = () => searchRef.current?.refreshEmployees();

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const handleSortChange = (newOptions: SortOption) => {
    if (newOptions.orderBy === orderBy && newOptions.sort === sort) return;
    setOrderBy(newOptions.orderBy);
    setSort(newOptions.sort);
  };

  const handleAdd = () => {
    setAddEmployeeDrawerOpened(!addEmployeeDrawerOpened);
  };
  useEffect(() => {
    if (selectedLink) {
      setParentSelectedColumn('links');
      setParentSearchCondition(`name:${selectedLink.name},url:${selectedLink.link}`);
    } else {
      setParentSearchCondition('');
    }
  }, [selectedLink]);

  useEffect(() => {
    if (selectedFile) {
      setParentSelectedColumn('files');
      setParentSearchCondition(`name:${selectedFile.fileName}`);
    } else {
      setParentSearchCondition('');
    }
  }, [selectedFile]);

  const query = useQuery();
  const col = query.get('col');
  const val = query.get('val');
  const isFromUnitsPage = query.get('fromUnits') === 'true';
  useEffect(() => {
    // setParentSelectedColumn(col);
    // setParentSearchCondition(val);
    setSelectedColumn(col || 'firstName');
    setSearchTerm(val || '');
  }, [col, val]);

  const clearItemSelection = () => {
    setSelectedLink(null);
    setSelectedFile(null);
  };

  const tableRef = useRef<TableUiHandle>(null);
  const onSearchStart = useCallback(() => {
    tableRef?.current?.setPage(0);
  }, []);

  const {
    openImportModal,
    openUpdateModal,
    openDeleteModal,
    insertIntoDatabase,
    importError,
    setImportError,
  } = useCsvbox({
    importerLicence:
      theme?.themeInternal?.csvImporterLicence || process.env.REACT_APP_CSVBOX_LICENCE_IMPORT,
    updaterLicence:
      theme?.themeInternal?.csvUpdaterLicence || process.env.REACT_APP_CSVBOX_LICENCE_UPDATE,
    deleterLicence:
      theme?.themeInternal?.csvDeleterLicence || process.env.REACT_APP_CSVBOX_LICENCE_DELETE,
  });
  const startImport = useCallback(() => {
    setIsOneUserUpload(false);
    openImportModal(
      theme?.themeInternal.amountLicences || 0,
      employees.length,
      (data, type, orderCard) => {
        setLicenseModalContent(type);
        setOrderCard(orderCard);
        setShowModalLicense(true);
        setImportedData(data);
      },
    );
  }, [employees.length, openImportModal, theme?.themeInternal.amountLicences]);

  const getLicenseText = () => {
    if (LicenseModalContents === 'noOrderCardWithExceedLicense') {
      return {
        line0: t('csvImport.toast.exceedtotalLicensing0'),
        line1: nl2br(t('csvImport.toast.exceedtotalLicensing1')),
        line2:
          t('csvImport.toast.exceedtotalLicensing2') +
          ' ' +
          (theme?.themeInternal.amountLicences || 0),
      };
    }
    if (LicenseModalContents === 'orderCardWithExceedLicense') {
      return {
        line0: t('csvImport.toast.exceedtotalLicensing0'),
        line1: nl2br(
          t('csvImport.toast.exceedtotalLicensing3').replace('@amount', orderCard.toString()),
        ),
        line2:
          t('csvImport.toast.exceedtotalLicensing2') +
          ' ' +
          (theme?.themeInternal.amountLicences || 0),
      };
    }
    if (LicenseModalContents === 'orderCardWithNoneExceedLicense') {
      return {
        line0: t('csvImport.toast.otherCards'),
        line1: t('csvImport.toast.selectedCards').replace('@amount', orderCard.toString()),
        line2: t('csvImport.toast.cardsMessage'),
      };
    }
  };

  const [saving, setSaving] = useState(false);
  const handleLicenseModalConfirm = async () => {
    if (isOneUserUpload) {
      setSaving(true);
      try {
        await createEmployees(getAccessTokenSilently, createEmployeeForm);
        toast.success(t('createdSuccessMD'));
        setIsUnsaved(false);
        setAddEmployeeDrawerOpened(false);
        refreshEmployees();
      } catch (error) {
        toast.error(error.message || t('error.general'));
      }
      setShowModalLicense(false);
      setSaving(false);
    } else {
      insertIntoDatabase(importedData);
    }
  };

  return (
    <>
      {Boolean(importError) && (
        <LtDialog
          open
          onClose={() => setImportError(undefined)}
          onCancel={() => setImportError(undefined)}
          title={t('csvImport.errors.header')}
          withActionDivider
        >
          {importError}
        </LtDialog>
      )}
      <AddEmployee
        open={addEmployeeDrawerOpened}
        onBackClick={() => setAddEmployeeDrawerOpened(false)}
        setCreateEmployeeForm={setCreateEmployeeForm}
        showLicensesWarning={() => setShowModalLicense(true)}
        refreshEmployees={refreshEmployees}
      />

      <Box p={2} sx={{ height: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})` }}>
        <Paper
          variant='outlined'
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CustomTableToolbar>
            <Search
              ref={searchRef}
              onSearchStart={onSearchStart}
              parentSearchCondition={parentSearchCondition}
              setParentSearchCondition={setParentSearchCondition}
              parentSelectedColumn={parentSelectedColumn}
              onTextChange={clearItemSelection}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              orderBy={orderBy}
              sort={sort?.toLowerCase()}
            />
            <Box sx={{ ml: 'auto' }}>
              <HelpButton configKey='help-sidebar-profiles' />
            </Box>
            <LtMultiActionButton
              label={t('csvImport.label')}
              options={[
                { text: t('csvImport.add'), onClick: startImport },
                { text: t('csvImport.update'), onClick: openUpdateModal },
                { text: t('csvImport.delete'), onClick: openDeleteModal },
              ]}
              size='medium'
              variant='outlined'
              color='primary'
              sx={{ mr: 1 }}
              endIcon={<KeyboardArrowDown />}
              menuItemProps={{ style: { fontSize: '1.4rem' } }}
            />
            {isFromUnitsPage ? (
              <LtMultiActionButton
                label={t('addProfile')}
                options={[
                  {
                    text: t('mdUnits.addExistingUser'),
                    onClick: () => setAddUserToUnitExplainerOpened(true),
                  },
                  {
                    text: t('mdUnits.createNewUser'),
                    onClick: () => {
                      setIsOneUserUpload(true);
                      handleAdd();
                    },
                  },
                ]}
                endIcon={<KeyboardArrowDown />}
                menuItemProps={{ style: { fontSize: '1.4rem' } }}
              />
            ) : (
              <MUIButton
                startIcon={<AddOutlined />}
                size='medium'
                onClick={() => {
                  setIsOneUserUpload(true);
                  handleAdd();
                }}
                aria-haspopup='dialog'
              >
                {t('addProfile')}
              </MUIButton>
            )}
          </CustomTableToolbar>

          <Table
            tableRef={tableRef}
            employees={employees}
            SelectedObjects={{
              selectedLinksProps: { selectedLink, setSelectedLink },
              selectedFilesProps: { selectedFile, setSelectedFile },
            }}
            refreshEmployees={refreshEmployees}
            orderBy={orderBy}
            sort={sort}
            onSortChange={handleSortChange}
          />
        </Paper>
      </Box>

      <LtDialog
        open={showModalLicense}
        title={getLicenseText().line0}
        onCancel={() => {
          setShowModalLicense(false);
          toast.dismiss('csvboxRequestLoading');
        }}
        onClose={() => {
          setShowModalLicense(false);
          toast.dismiss('csvboxRequestLoading');
        }}
        onConfirm={handleLicenseModalConfirm}
        loading={saving}
      >
        <Typography mb={4}>{getLicenseText().line1}</Typography>
        <Typography variant='body2'>{getLicenseText().line2}</Typography>
      </LtDialog>
      <AddUserToUnitPopup
        open={addUserToUnitExplainerOpened}
        onClose={() => setAddUserToUnitExplainerOpened(false)}
      />
    </>
  );
};

const Page = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const { isFeatureAllowed } = useTierInfo();
  const isFromUnits = query.get('fromUnits') === 'true';
  const Content = withNav(
    isFeatureAllowed(FEATURE.MULTI_USER)
      ? MdProfiles
      : () => (
          <FeatureTeaser text={t('upgradeTeaser.feature.profiles')} imageSrc={profilesTeaserImg} />
        ),
    {
      tTitle: isFromUnits ? 'profileOverviewFromUnits' : 'profileOverview',
      showBackButton: isFromUnits && {
        linkTo: '/md/units',
      },
    },
    {},
  );
  return <Content />;
};

export default Page;
